import React, { useState, useEffect } from "react";
import axios from "axios";

export default function TravelOptions({ travelOptions }) {
  return (
    <div className="flex flex-col py-5 gap-y-4 px-4 items-center justify-center w-full">
      {travelOptions ? (
        <>
          {/* Flight Booking */}
          <div className="flex flex-col items-start justify-start w-full p-2">
            <span className="text-base lg:text-xl font-normal tracking-wide">
              Flight Booking:
            </span>
            <span className="text-sm">{travelOptions.byAir.description}</span>
            <div className="text-sm">
              <button className="mt-2 mr-2 p-2 font-bold border-gray-400 border-[1px] rounded-lg">
                <a
                  href="https://www.skyscanner.co.in/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  SkyScanner
                </a>
              </button>
              <button className="mt-2 mr-2 p-2 font-bold border-gray-400 border-[1px] rounded-lg">
                <a
                  href="https://www.booking.com/flights"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Booking.com
                </a>
              </button>
              <button className="mt-2 mr-2 p-2 font-bold border-gray-400 border-[1px] rounded-lg">
                <a
                  href="https://www.makemytrip.com/flights/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  MakeMyTrip
                </a>
              </button>
              <button className="mt-2 mr-2 p-2 font-bold border-gray-400 border-[1px] rounded-lg">
                <a
                  href="https://www.kayak.co.in/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Kayak
                </a>
              </button>
            </div>
          </div>

          <hr className="border border-gray-500 bg-gray-200 w-full" />

          {/* Cab Booking */}
          <div className="flex flex-col items-start justify-start w-full p-2">
            <span className="text-base lg:text-xl font-normal tracking-wide">
              Cab Booking:
            </span>
            <span className="text-sm">{travelOptions.byCar.description}</span>
            <div className="text-sm">
              <button className="mt-2 mr-2 p-2 font-bold border-gray-400 border-[1px] rounded-lg">
                <a
                  href="https://www.zoomcar.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ZoomCar
                </a>
              </button>
              <button className="mt-2 mr-2 p-2 font-bold border-gray-400 border-[1px] rounded-lg">
                <a
                  href="https://www.olacabs.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  OlaCabs
                </a>
              </button>
              <button className="mt-2 mr-2 p-2 font-bold border-gray-400 border-[1px] rounded-lg">
                <a
                  href="https://www.makemytrip.com/car-rental/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  MakeMyTrip
                </a>
              </button>
              <button className="mt-2 mr-2 p-2 font-bold border-gray-400 border-[1px] rounded-lg">
                <a
                  href="https://www.kayak.co.in/cars/sitemap"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Kayak
                </a>
              </button>
            </div>
          </div>

          <hr className="border border-gray-500 bg-gray-200 w-full" />

          {/* Bus Booking */}
          <div className="flex flex-col items-start justify-start w-full p-2">
            <span className="text-base lg:text-xl font-normal tracking-wide">
              Bus Booking:
            </span>
            <span className="text-sm">{travelOptions.byBus.description}</span>
            <div className="text-sm">
              <button className="mt-2 mr-2 p-2 font-bold border-gray-400 border-[1px] rounded-lg">
                <a
                  href="https://www.redbus.in/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  RedBus
                </a>
              </button>
              <button className="mt-2 mr-2 p-2 font-bold border-gray-400 border-[1px] rounded-lg">
                <a
                  href="https://www.busbud.com/en/bt/bus-tickets"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  BusBud
                </a>
              </button>
              <button className="mt-2 mr-2 p-2 font-bold border-gray-400 border-[1px] rounded-lg">
                <a
                  href="https://www.abhibus.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  AbhiBus
                </a>
              </button>
              <button className="mt-2 mr-2 p-2 font-bold border-gray-400 border-[1px] rounded-lg">
                <a
                  href="https://www.goibibo.com/bus/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GoIbibo
                </a>
              </button>
            </div>
          </div>

          <hr className="border border-gray-500 bg-gray-200 w-full" />

          {/* Train Booking */}
          <div className="flex flex-col items-start justify-start w-full p-2">
            <span className="text-base lg:text-xl font-normal tracking-wide">
              Train Booking:
            </span>
            <span className="text-sm">{travelOptions.byTrain.description}</span>
            <div className="text-sm">
              <button className="mt-2 mr-2 p-2 font-bold border-gray-400 border-[1px] rounded-lg">
                <a
                  href="https://www.trainman.in/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  TrainMan
                </a>
              </button>
              <button className="mt-2 mr-2 p-2 font-bold border-gray-400 border-[1px] rounded-lg">
                <a
                  href="https://www.confirmtkt.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ConfirmTkt
                </a>
              </button>
              <button className="mt-2 mr-2 p-2 font-bold border-gray-400 border-[1px] rounded-lg">
                <a
                  href="https://www.makemytrip.com/railways/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  MakeMyTrip
                </a>
              </button>
              <button className="mt-2 mr-2 p-2 font-bold border-gray-400 border-[1px] rounded-lg">
                <a
                  href="https://www.internationalrail.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  InternationalRail
                </a>
              </button>
              <button className="mt-2 mr-2 p-2 font-bold border-gray-400 border-[1px] rounded-lg">
                <a
                  href="https://www.irctc.co.in/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  IRCTC
                </a>
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <p>Generating travel options...</p>
        </>
      )}
    </div>
  );
}
