import React, { useState, useEffect, useRef} from "react";
import { Link } from "react-router-dom";
import "../assets/css/style.css";
import logo from "../assets/images/logo_blackbg.gif";
import { FcGoogle } from "react-icons/fc";
import { FiMenu } from "react-icons/fi";
import { AiFillCloseCircle } from "react-icons/ai";
import { SiGoogletranslate } from "react-icons/si";
import NavbarSidebar from "./NavbarSidebar";
import Cookies from 'js-cookie';

function Navbar() {

  const [userName, setUserName] = useState(null);
  const [userPhoto, setUserPhoto] = useState(null);
  const popupRef = useRef(null);
  const languageRef = useRef(null);
  const otherOptionsPopupRef = useRef(null);

  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
      if (languageRef.current && !languageRef.current.contains(event.target)) {
        setLanguageDropdownOpen(false);
      }
      if (
        otherOptionsPopupRef.current &&
        !otherOptionsPopupRef.current.contains(event.target)
      ) {
        setOtherOptionsDropdownOpen(false);
      }
    }
    const updateState = () => {
      setUserName(localStorage.getItem("userName"));
      setUserPhoto(localStorage.getItem("userPhoto"));
    };
    updateState();
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [userPhoto, userName]);

  const [showHide, setShowHide] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);
  const [otherOptionsdropdownOpen, setOtherOptionsDropdownOpen] = useState(false);


  const googleAuth = () => {
    window.open(
      `${process.env.REACT_APP_API_URL}/auth/google/callback`,
      "_self"
    );
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  // funcion to set a cookie
  //const domain = window.location.hostname === 'localhost' ? undefined : '.wandermewell.com';

  function setCookie(name, value, days) {
    deleteCookie(name);
    Cookies.set(name, value, {
      domain: window.location.hostname,
      path: "/",
      secure: true,
      sameSite: "None",
    });
    Cookies.set(name, value, {
      domain: '.wandermewell.com',
      path: '/',                   
      secure: true,                
      sameSite: 'None'             
    });
  }
  // function to delete cookie
  function deleteCookie(name) {
    Cookies.remove(name);
    Cookies.remove(name, {
      domain: window.location.hostname,
      path: "/",
    });
    Cookies.remove(name, {
      domain: '.wandermewell.com',
      path: '/'
    });
  }

  // Attach event listener to window/tab close
  // window.addEventListener("beforeunload", () => {
  //   // Delete the cookie on tab close
  //   deleteCookie("googtrans");
  // });

  const toggleDropdownOtherOptions = () => {
    setOtherOptionsDropdownOpen(!otherOptionsdropdownOpen);
  };

  return (
    <>
      <section className="bg-black z-10 px-5 sticky text-gray-400 top-0 flex flex-col items-center justify-center w-screen">
        <div className="flex flex-col gap-y-2.5 items-center justify-center w-full">
          <div className="flex relative items-center justify-between w-full">
            <a className="flex items-end justify-start cursor-pointer" href="/">
              <img
                src={logo}
                className="h-16 w-64 object-cover overflow-y-hidden"
                alt="loading error"
              />
            </a>

            <span className="hidden text-[1rem] font-bold items-center justify-center gap-x-4 lg:flex">
              <span className="cursor-pointer">
                <a
                  href="/"
                  onClick={() => {
                    window.scroll({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  Home
                </a>
              </span>
              |
              <span className="flex items-center justify-center cursor-pointer">
                <a
                  href="/packages"
                  onClick={() => {
                    window.scroll({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  Packages
                </a>
              </span>
              |
              <span className="flex items-center justify-center cursor-pointer">
                <a
                  href="/community-trips"
                  onClick={() => {
                    window.scroll({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  Community Trips
                </a>
              </span>
              |
              <span>
                <div className="relative">
                  <button
                    className="flex justify-start items-center focus:outline-none"
                    type="button"
                    onClick={() =>
                      setLanguageDropdownOpen(!languageDropdownOpen)
                    }
                  >
                    <SiGoogletranslate className="text-2xl" />
                  </button>
                  {languageDropdownOpen && (
                    <ul className="dropdown-menu absolute right-0 mt-2 w-48 bg-gray-800 text-white rounded shadow-lg z-50" ref={languageRef}>
                      <li>
                        <button
                          className="dropdown-item block px-4 py-2 w-full text-left hover:bg-gray-700"
                          translate="no"
                          onClick={() => {
                            deleteCookie("googtrans");
                            //important
                            window.location.reload();
                          }}
                        >
                          English
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item block w-full text-left px-4 py-2 hover:bg-gray-700"
                          translate="no"
                          onClick={() => {
                            setCookie("googtrans", `/en/hi`);
                            //important ಕನ್ನಡ
                            window.location.reload();
                          }}
                        >
                          हिन्दी (Hindi)
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item block w-full text-left px-4 py-2 hover:bg-gray-700"
                          translate="no"
                          onClick={() => {
                            setCookie("googtrans", `/en/kn`);
                            //important kannada
                            window.location.reload();
                          }}
                        >
                          ಕನ್ನಡ (Kannada)
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item block w-full text-left px-4 py-2 hover:bg-gray-700"
                          translate="no"
                          onClick={() => {
                            setCookie("googtrans", `/en/ta`);
                            //important tamil
                            window.location.reload();
                          }}
                        >
                          தமிழ் (Tamil)
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item block w-full text-left px-4 py-2 hover:bg-gray-700"
                          translate="no"
                          onClick={() => {
                            setCookie("googtrans", `/en/te`);
                            //important telugu
                            window.location.reload();
                          }}
                        >
                          తెలుగు (Telugu)
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item block w-full text-left px-4 py-2 hover:bg-gray-700"
                          translate="no"
                          onClick={() => {
                            setCookie("googtrans", `/en/bn`);
                            //important telugu
                            window.location.reload();
                          }}
                        >
                          বাংলা (Bengali)
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item block w-full text-left px-4 py-2 hover:bg-gray-700"
                          translate="no"
                          onClick={() => {
                            setCookie("googtrans", `/en/ml`);
                            //important telugu
                            window.location.reload();
                          }}
                        >
                          മലയാളം (Malayalam)
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item block w-full text-left px-4 py-2 hover:bg-gray-700"
                          translate="no"
                          onClick={() => {
                            setCookie("googtrans", `/en/gu`);
                            //important telugu
                            window.location.reload();
                          }}
                        >
                          ગુજરાતી (Gujarati)
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item block w-full text-left px-4 py-2 hover:bg-gray-700"
                          translate="no"
                          onClick={() => {
                            setCookie("googtrans", `/en/mr`);
                            //important telugu
                            window.location.reload();
                          }}
                        >
                          मराठी (Marathi)
                        </button>
                      </li>
                    </ul>
                  )}
                </div>
              </span>
              |
              {userPhoto === null && (
                <span
                  className="text-[0.8rem] text-white font-normal flex items-center justify-center cursor-pointer border-2 border-white-400 p-1 rounded-md
                 hover:bg-orange-600"
                  onClick={googleAuth}
                >
                  <FcGoogle className="mr-1 text-lg" />
                  <span
                    translate="no"
                    className="cursor-pointer transition-all duration-300"
                  >
                    Sign In
                  </span>
                </span>
              )}
              {userPhoto && (
                <div className="relative">
                  <button
                    className="flex justify-start items-center focus:outline-none"
                    type="button"
                    onClick={toggleDropdown}
                  >
                    <span>
                      <img
                        src={
                          userPhoto
                            ? userPhoto
                            : `https://ui-avatars.com/api/?name=${userName}`
                        }
                        title="Profile"
                        alt={userName}
                        className="h-6 w-6 rounded-full"
                      />
                    </span>
                  </button>
                  {dropdownOpen && (
                    <ul
                      className="dropdown-menu absolute right-0 mt-2 w-48 bg-gray-800 text-white rounded shadow-lg z-50"
                      ref={popupRef}
                    >
                      <li>
                        <Link
                          className="dropdown-item block px-4 py-2 hover:bg-gray-700"
                          to="/your-trips"
                          onClick={() => {
                            setDropdownOpen(false);
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          Profile
                        </Link>
                      </li>
                      <li>
                        <button
                          className="dropdown-item block w-full text-left px-4 py-2 hover:bg-gray-700"
                          onClick={() => {
                            localStorage.removeItem("userId");
                            localStorage.removeItem("userName");
                            localStorage.removeItem("userPhoto");
                            localStorage.removeItem("user");

                            window.location.reload();
                            window.open(
                              `${process.env.REACT_APP_API_URL}/auth/logout`,
                              "_self"
                            );
                          }}
                        >
                          Logout
                        </button>
                      </li>
                    </ul>
                  )}
                </div>
              )}
              <span className="flex items-center justify-center cursor-pointer">
                <div className="relative">
                  <button
                    className="flex justify-start items-center focus:outline-none"
                    type="button"
                    onClick={toggleDropdownOtherOptions}
                  >
                    <FiMenu className="text-2xl" />
                  </button>
                  {otherOptionsdropdownOpen && (
                    <ul
                      className="dropdown-menu absolute right-0 mt-2 w-48 bg-gray-800 text-white rounded shadow-lg z-50"
                      ref={otherOptionsPopupRef}
                    >
                      <li>
                        <Link
                          className="dropdown-item block px-4 py-2 hover:bg-gray-700"
                          to="/about"
                          onClick={() => {
                            window.scroll({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          About Us
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item block px-4 py-2 hover:bg-gray-700"
                          to="/services"
                          onClick={() => {
                            window.scroll({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          Services
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item block px-4 py-2 hover:bg-gray-700"
                          to="/blog"
                          onClick={() => {
                            window.scroll({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          Blogs
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item block px-4 py-2 hover:bg-gray-700"
                          to="/contact-us"
                          onClick={() => {
                            window.scroll({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          Contact Us
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>
              </span>
            </span>

            <span className="absolute top-3.4 right-2.5 z-20 text-2xl lg:hidden">
              {showHide ? (
                <FiMenu onClick={() => setShowHide(!showHide)} />
              ) : (
                <AiFillCloseCircle
                  onClick={() => setShowHide(!showHide)}
                  className="text-3xl"
                />
              )}
            </span>
          </div>

          <NavbarSidebar
            show={showHide}
            setCookie={setCookie}
            deleteCookie={deleteCookie}
          />
        </div>

        <div
          className={`${
            showHide ? "hidden" : "flex"
          } fixed left-0 top-0 w-screen h-screen bg-[#000000c4]`}
          onClick={() => setShowHide(!showHide)}
        ></div>
      </section>
    </>
  );
}

export default Navbar;
