import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import logo from "../assets/images/logo.png";
import GetHotels from "../components/GetHotels.js";
import HotelDetails from "../components/HotelDetails.js";
import { FaSpinner } from 'react-icons/fa';
import { IoCloseSharp as Close } from "react-icons/io5";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import "../assets/css/Itinerary.css";
import ReactGA from "react-ga4";
import axios from "axios";
ReactGA.initialize("G-JSH530JDVC");
ReactGA.send({ hitType: "pageview", page: "/itinerary", title: "itinerary" });

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

function Hotels() {
  mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;
  const { id } = useParams();
  const [showMap, setShowMap] = useState(false);

  const mapContainer = useRef(null);
  const map = useRef(null);

  const [priorDetails, setPriorDetails] = useState(null);
  const [currentHotel, setCurrentHotel] = useState(null);
  const [showCurrentHotelModal, setShowCurrentHotelModal] = useState(false);
  const [currentPicture, setCurrentPicture] = useState(null);

  let [showDesktopView, setShowDesktopView] = useState(true);

  const isMobile = useMediaQuery({ query: `(max-width: 1024px)` });

  useEffect(() => {
    window.getyourguide && window.getyourguide.Widgets.Loader.load();

    if (isMobile) {
      setShowDesktopView(false);
    }
  }, [isMobile, showDesktopView]);

  function onCoordinatesChange(coordinates) {
    new mapboxgl.Marker({ color: "#FF0000" })
      .setLngLat([coordinates.longitude, coordinates.latitude])
      .addTo(map.current);
    map.current.flyTo({
      center: [coordinates.longitude, coordinates.latitude],
      essential: true,
      zoom: 15,
      style: "mapbox://styles/mapbox/streets-v11",
    });
  }

  const fetchDetailsToGenerateItinerary = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/trip/get-prior-itinerary-details/${id}`,
        { withCredentials: false }
      );

      //console.log(response.data.message);
      setPriorDetails(response.data.message);

      if (map.current) return;
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/outdoors-v12",
        projection: "globe",
        center: [response.data.message.long, response.data.message.lat],
        zoom: 8,
      });
    } catch (error) {
      console.error(error);
    }
  }, [id]);

  useEffect(() => {
    fetchDetailsToGenerateItinerary();
  }, [fetchDetailsToGenerateItinerary]);

  const handleModals = () => {
    setShowCurrentHotelModal(false);
    setShowMap(false);
  };
  const [sortBy, setSortBy] = useState("number_of_reviews");
  const [hotels, setHotels] = useState([]);
  const [hotelFetching, setHotelFetching] = useState(false);

  React.useEffect(() => {
    const fetchHotels = async () => {
      setHotelFetching(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/searchHotels`,
          {
            city: priorDetails.placeName,
            sortBy: sortBy,
          }
        );
        fetchAndAppendPrices(response.data)
          .then((hotelsWithPrices) => {
            setHotels(hotelsWithPrices);
            // Use hotelsWithPrices as needed
          })
          .catch((error) => {
            console.error("Error fetching hotel prices:", error);
          })
        setHotelFetching(false);
      } catch (error) {
        console.log(error.message);
      } finally {
        setHotelFetching(false);
      }
    };

    const calculateCheckOutDate = (checkInDate, numberOfNights) => {
      const checkIn = new Date(checkInDate);
      checkIn.setDate(checkIn.getDate() + numberOfNights);

      const year = checkIn.getFullYear();
      const month = (checkIn.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
      const day = checkIn.getDate().toString().padStart(2, "0");

      return `${year}-${month}-${day}`;
    };

    const fetchHotelDetails = async (hotel_id) => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/hotelDetails`,
          {
            criteria: {
              additional: {
                currency: "INR",
                language: "en-us",
                occupancy: {
                  numberOfAdult: 2,
                  numberOfChildren: 0,
                },
              },
              checkInDate: priorDetails.date,
              checkOutDate: calculateCheckOutDate(priorDetails.date, 1),
              hotelId: [hotel_id],
            },
          }
        );
        const hotelDetails = response?.data?.results?.[0];
        return {
          ...hotelDetails,
          price: Math.round(hotelDetails.dailyRate), // Convert the price to an integer using Math.round
        }; // Assuming the API returns a JSON object with fields including `dailyRate` and `landingUrl`
      } catch (error) {
        console.error(`Error fetching details for hotel ${hotel_id}:`, error);
        return {}; // Return an empty object if the API call fails
      }
    };

    const fetchAndAppendPrices = async (hotels) => {
      const hotelPromises = hotels.map(async (hotel) => {
        const additionalInfo = await fetchHotelDetails(hotel.hotel_id);
        return { ...hotel, ...additionalInfo }; // Merge the original hotel object with additional information
      });

      const hotelsWithAdditionalInfo = await Promise.all(hotelPromises);
      return hotelsWithAdditionalInfo;
    };

    fetchHotels();
  }, [priorDetails, sortBy]);
  return (
    <>
      <div className="flex justify-center items-start mx-auto h-[91vh] w-full">
        <div className="flex flex-col items-center justify-center w-full lg:w-[60%] h-full">
          {priorDetails ? (
            <div className="h-[100vh] overflow-y-scroll w-full mx-auto hidden-scrollbar">
              <div className="p-2">
                <div className="flex flex-wrap w-full justify-between items-center pb-2">
                  <h1 className="text-lg font-bold text-center">
                    Top Hotels in {priorDetails.placeName}
                  </h1>
                  <div className="flex flex-wrap items-center justify-center w-fit">
                    <div className="w-fit flex flex-wrap gap-1">
                      <div className="relative flex items-center justify-center text-sm text-left w-fit h-full py-1">
                        Sort by
                      </div>
                      <span
                        className={`px-2 text-sm py-1 hover:cursor-pointer border border-orange-500 rounded-md duration-500 ${
                          sortBy === "number_of_reviews"
                            ? "text-white bg-orange-500"
                            : "text-black"
                        }`}
                        onClick={() => setSortBy("number_of_reviews")}
                      >
                        No of Reviews
                      </span>
                      <span
                        className={`px-2 text-sm py-1 hover:cursor-pointer border border-orange-500 rounded-md duration-500 ${
                          sortBy === "rating_average"
                            ? "text-white bg-orange-500"
                            : "text-black"
                        }`}
                        onClick={() => setSortBy("rating_average")}
                      >
                        Average Rating
                      </span>
                      <span
                        className={`px-2 text-sm py-1 hover:cursor-pointer border border-orange-500 rounded-md duration-500 ${
                          sortBy === "star_rating"
                            ? "text-white bg-orange-500"
                            : "text-black"
                        }`}
                        onClick={() => setSortBy("star_rating")}
                      >
                        Star Rating
                      </span>
                    </div>
                  </div>
                </div>

                {!hotelFetching ? (
                  <GetHotels
                  setShowMap={setShowMap}
                  hotels={hotels}
                  onCoordinatesChange={onCoordinatesChange}
                  currentHotel={currentHotel}
                  setCurrentHotel={setCurrentHotel}
                  setShowCurrentHotelModal={setShowCurrentHotelModal}
                  setCurrentPicture={setCurrentPicture}
                />
                ) : (
                  <div className="flex flex-col items-center justify-center w-full h-auto px-2 py-8">
                    <FaSpinner className="animate-spin text-3xl text-gray-500" />
                    <span className="font-black uppercase text-lg text-center">
                      Hold On <br /> We are fetching the best hotels for you{" "}
                    </span>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className="h-11 flex flex-row-reverse fixed bottom-0 left-0 bg-white z-50 md:relative items-center justify-between w-full px-3 pb-1 border-t-2 border-solid border-gray-400 ">
            <button
              type="button"
              className="z-1 inline-block text-center select-none border font-semibold whitespace-no-wrap rounded px-2 py-1 no-underline bg-[#fd630d] text-white hover:scale-110 my-3 transition-all"
              onClick={() => (window.location.href = `/create-itinerary/${id}`)}
            >
              Create Itinerary
            </button>
            <button
              className="z-1 inline-block align-middle text-center select-none border whitespace-no-wrap rounded py-1 px-2 leading-normal no-underline bg-[#fd630d] font-semibold text-white lg:hidden"
              type="button"
              onClick={() => setShowMap(!showMap)}
            >
              Show map
            </button>
          </div>
        </div>

        {
          <div className="absolute flex flex-col items-center justify-center w-full lg:w-[40%] overflow-y-hidden lg:relative">
            {showDesktopView && (
              <div className={`map w-full`}>
                <div
                  ref={mapContainer}
                  className="map-container z-0"
                  style={{
                    minHeight: "500px",
                    overflow: "hidden",
                    height: "92vh",
                    width: "100%",
                  }}
                />
              </div>
            )}
            {!showDesktopView && (
              <div
                className={`bg-color-secondary z-10 duration-500 ${
                  !showMap ? "translate-x-full" : "translate-x-0"
                } flex flex-col fixed h-full w-full top-0 right-0 `}
              >
                <div className="w-full lg:hidden flex flex-row justify-between items-center">
                  <img className="w-10" src={logo} alt="logo" />
                  <h5 className="text-center text-lg align-middle font-semibold">
                    Map View
                  </h5>
                  <Close
                    className="text-3xl cursor-pointer font-extrabold text-orange-500"
                    onClick={() => {
                      handleModals();
                    }}
                  />
                </div>
                <div
                  ref={mapContainer}
                  className="map-container"
                  style={{
                    minHeight: "500px",
                    overflow: "hidden",
                    height: "100vh",
                    width: "100%",
                    zIndex: "0",
                  }}
                />
              </div>
            )}
            {currentPicture && <div className="w-36 h-36 bg-white absolute top-5 right-5 z-50 rounded-md drop-shadow-xl">
              <img src={currentPicture} className="h-full w-full object-cover rounded-md drop-shadow-xl p-1" alt="wmw" />
            </div>}
            <HotelDetails
              showCurrentHotelModal={showCurrentHotelModal}
              currentHotel={currentHotel}
              setshowCurrentHotelModal={setShowCurrentHotelModal}
            />
          </div>
        }
      </div>
    </>
  );
}
export default Hotels;
