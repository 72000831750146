import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { IoMdClose as Close } from "react-icons/io";
import { FaGripLines, FaTrash, FaShare, FaStar } from "react-icons/fa";
import Accordian, { AccordianItem } from "./Accordion";
import axios from "axios";
import "../assets/css/style.css";

export default function TripItinerary({
  date,
  itinerary,
  onCoordinatesChange,
  setActivity,
  setShowActivityModal,
  setShowMap,
  showRegenerateOption,
  setCurrentPicture
}) {
  const [hoveredActivity, setHoveredActivity] = useState(null);
  const [sharePopup, setSharePopup] = useState({});
  const [draggedActivity, setDraggedActivity] = useState(null);
  const [dropTargetIndex, setDropTargetIndex] = useState(null);
  const { id } = useParams();

  const WeekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const Months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [picture, setPicture] = React.useState(null);

  const startingDate = new Date(date);

  const getDay = (index) => WeekDays[(startingDate.getDay() + index) % 7];
  const getMonth = (index, month) => {
    const date = startingDate.getDate() + index;
    return date <= 30
      ? Months[startingDate.getMonth() % 12]
      : Months[(startingDate.getMonth() + 1) % 12];
  };
  const getDate = (index, month) => {
    const date = startingDate.getDate() + index;
    return date <= 30 ? date : date % 30;
  };

  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status
  const [showSignInPopup, setShowSignInPopup] = useState(false); // State to show sign-in popup

  const handleActivityDetails = (givenActivity) => {
    setShowMap(true);
    setShowActivityModal(true);
    setActivity(givenActivity);
  };

  const loginCheck = () => {
    // Check login status on component mount
    const storedUsername = localStorage.getItem("userName");
    const storedUserId = localStorage.getItem("userId");

    if (storedUsername && storedUserId) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
      setShowSignInPopup(true);
    }
  };

  const handleButtonClick = (action) => {
    loginCheck();
    if (isLoggedIn) {
      action(); // Execute the action if logged in
    } else {
      setShowSignInPopup(true); // Show sign-in popup if not logged in
    }
  };

  const handleDragStart = (activityIndex, dayIndex) => {
    setDraggedActivity({ activityIndex, dayIndex });
  };

  const handleDragOver = (event, activityIndex) => {
    event.preventDefault();
    setDropTargetIndex(activityIndex);
  };

  const handleDrop = async (dayIndex) => {
    if (draggedActivity && draggedActivity.dayIndex === dayIndex) {
      const { activityIndex: sourceIndex } = draggedActivity;
      const activities = [...itinerary[dayIndex].activities];
      const [draggedItem] = activities.splice(sourceIndex, 1);
      activities.splice(dropTargetIndex, 0, draggedItem);

      itinerary[dayIndex].activities = activities;

      // Interchange activities within the day using the API
      const { googlePlaceId: googlePlaceId1 } = activities[sourceIndex];
      const { googlePlaceId: googlePlaceId2 } = activities[dropTargetIndex];

      // console.log(googlePlaceId1);
      // console.log(googlePlaceId2);
      // console.log(id);
      // console.log(itinerary[dayIndex].did);

      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/sleeve/swapActivity`,
        {
          uid: id,
          dayId: itinerary[dayIndex].did,
          googlePlaceId1,
          googlePlaceId2,
        }
      );

      setDraggedActivity(null);
      setDropTargetIndex(null);
    }
  };

  const handleDragEnd = () => {
    setDraggedActivity(null);
    setDropTargetIndex(null);
  };

  const handleDeleteButtonClick = async (activityIndex, dayIndex) => {
    // Check if dayIndex and activityIndex are valid
    if (
      !itinerary[dayIndex] ||
      !itinerary[dayIndex].activities ||
      !itinerary[dayIndex].activities[activityIndex]
    ) {
      console.error("Invalid dayIndex or activityIndex");
      return;
    }

    const { googlePlaceId } = itinerary[dayIndex].activities[activityIndex];
    // Check if googlePlaceId is defined
    if (!googlePlaceId) {
      console.error("googlePlaceId is undefined");
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/sleeve/deleteActivity`,
        {
          uid: id,
          dayId: itinerary[dayIndex].did,
          googlePlaceId,
        }
      );

      // Remove the activity from the itinerary
      itinerary[dayIndex].activities.splice(activityIndex, 1);

      // Update the state to trigger a re-render
      setState(!state);
    } catch (error) {
      console.error("Error deleting activity:", error);
    }
  };

  const handleMoveActivity = async (
    activityIndex,
    fromDayIndex,
    toDayIndex
  ) => {
    const { googlePlaceId } = itinerary[fromDayIndex].activities[activityIndex];

    try {
      // Send the request to the backend to move the activity
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/sleeve/moveActivity`,
        {
          uid: id,
          fromDayId: itinerary[fromDayIndex].did,
          toDayId: itinerary[toDayIndex].did,
          googlePlaceId,
        }
      );

      // After successful response, move the activity on the frontend
      const activity = itinerary[fromDayIndex].activities.splice(
        activityIndex,
        1
      )[0];
      itinerary[toDayIndex].activities.push(activity);

      // Trigger re-render
      setState(!state);

      // Close the popup
      setSharePopup({});
    } catch (error) {
      console.error("Failed to move activity:", error);
    }
  };

  const popupRef = useRef(null);

  const handleShareButtonClick = (activityIndex, dayIndex) => {
    if (
      sharePopup.activityIndex === activityIndex &&
      sharePopup.dayIndex === dayIndex
    ) {
      setSharePopup({});
    } else {
      setSharePopup({ activityIndex, dayIndex });
    }
  };

  const [state, setState] = useState(false);

  useEffect(() => {
    const handleActivityHover = (activity) => {
      if (!activity) return;
      const coordinates = activity.location;
      if (coordinates) {
        onCoordinatesChange(coordinates);
      }
    };
    handleActivityHover(hoveredActivity);

    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setSharePopup({});
      }
    };

    if (
      sharePopup.activityIndex !== undefined &&
      sharePopup.dayIndex !== undefined
    ) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [hoveredActivity, onCoordinatesChange, sharePopup]);

  return (
    <>
      {itinerary && (
        <div className="tripItinerary pb-8 md:pb-10 lg:pb-2">
          <Accordian
            flush
            style={{ backgroundColor: "transparent" }}
            defaultactivekey="0"
          >
            {itinerary.map((item, dayIndex) => (
              <AccordianItem
                style={{ backgroundColor: "transparent" }}
                index={dayIndex}
                trigger={`${getDay(dayIndex)}, ${getMonth(
                  dayIndex,
                  startingDate.getMonth()
                )}, ${getDate(dayIndex, startingDate.getMonth())}`}
                value={dayIndex}
                eventKey={dayIndex}
                state={state}
                key={"accordian-item-" + dayIndex}
              >
                {item.activities && item.activities.length > 0 ? (
                  item.activities.map((activity, activityIndex) => (
                    <div
                      className="flex justify-start md:justify-between items-start mb-2"
                      draggable
                      onDragStart={() =>
                        handleButtonClick(handleDragStart(activityIndex, dayIndex))
                      }
                      onDragOver={(event) =>
                        handleDragOver(event, activityIndex)
                      }
                      onDrop={() => handleDrop(dayIndex)}
                      onDragEnd={handleDragEnd}
                      key={"item-activity-div-" + activityIndex}
                    >
                      <div
                        className="w-full"
                        key={"item-activity-div-" + activityIndex}
                      >
                        <div className="group block md:relative h-full md:h-[7rem] p-1 shadow-sm shadow-gray-500 flex flex-col items-start min-w-full rounded-lg">
                          {/* Image and Heading Side by Side */}
                          <div className="flex flex-row-reverse justify-between items-start w-full mb-2 md:mb-0">
                            {activity?.details.photos?.[0] && (
                              <div
                                className="w-20 h-20"
                                onClick={() =>
                                  setPicture(
                                    `https://places.googleapis.com/v1/${activity.details.photos[0].name}/media?maxHeightPx=800&maxWidthPx=1200&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
                                  )
                                }
                              >
                                <img
                                  className="pointer-events-none h-full w-full rounded-lg bg-cover bg-center object-cover shadow-sm"
                                  loading="lazy"
                                  alt=""
                                  width="100"
                                  height="100"
                                  decoding="async"
                                  data-nimg="1"
                                  style={{ color: "transparent" }}
                                  src={`https://places.googleapis.com/v1/${activity.details.photos[0].name}/media?maxHeightPx=400&maxWidthPx=600&key=${process.env.REACT_APP_GOOGLE_API_KEY}`}
                                />
                              </div>
                            )}

                            <div className="flex-1">
                              <h5
                                className="text-[18px] font-semibold md:hover:underline hover:cursor-pointer"
                                style={{ color: "#fd630d" }}
                                key={"activity-header-" + activityIndex}
                              >
                                <span
                                  className="hover:cursor-pointer font-semibold"
                                  key={"activity-header-span-" + activityIndex}
                                  onMouseEnter={() =>{
                                      setHoveredActivity(activity.details);
                                      setCurrentPicture(`https://places.googleapis.com/v1/${activity.details.photos[0].name}/media?maxHeightPx=400&maxWidthPx=600&key=${process.env.REACT_APP_GOOGLE_API_KEY}`);
                                    }
                                  }
                                  onMouseLeave={() => setHoveredActivity(null)}
                                  onClick={() =>
                                    handleActivityDetails(activity.details)
                                  }
                                >
                                  {activity?.details?.activity ||
                                    activity?.details?.displayName?.text ||
                                    "Chill here"}
                                <span className="block lg:hidden text-[10px] text-gray-500">Click to View details</span> 
                                </span>
                              </h5>
                              {activity?.details?.rating && (
                                <span className="flex items-center mt-1 text-md">
                                  <FaStar className="text-yellow-400 mr-1" />
                                  <span className="text-gray-600">
                                    User Rating: {activity?.details?.rating}/5
                                  </span>
                                  <span className="text-gray-600 font-semibold ml-0 md:ml-1">
                                    ({activity?.details?.userRatingCount})
                                  </span>
                                </span>
                              )}
                            </div>
                          </div>

                          {/* Other Details Below Heading */}
                          <div className="w-full block md:absolute top-0 md:top-16 md:w-auto flex flex-col text-sm mt-2 md:mt-0">
                            <span
                              key={"activity-body-remarks-" + activityIndex}
                            >
                              {activity?.details?.remarks ||
                                activity?.details?.editorialSummary?.text}
                            </span>
                            <span className="flex flex-row">
                              <span
                                className="font-semibold mt-1"
                                key={"activity-body-time-" + activityIndex}
                              >
                                {activity?.timings}:
                              </span>
                              <span
                                className="font-semibold mt-1"
                                key={"activity-body-duration-" + activityIndex}
                              >
                                ({activity?.details?.duration || "2 hours"})
                              </span>
                              {activity.details.entryFees !== undefined &&
                                activity.details.entryFees !== null && (
                                  <span
                                    className="font-semibold mt-1"
                                    key={"activity-body-cost-" + activityIndex}
                                  >
                                    {activity.details.entryFees === 0 ||
                                    activity.details.entryFees === "0"
                                      ? ": Free"
                                      : " : Cost:- ₹" +
                                        activity?.details.entryFees +
                                        " INR."}
                                  </span>
                                )}
                            </span>
                          </div>
                        </div>
                      </div>
                      {showRegenerateOption && (
                        <div className="buttons flex flex-col ml-1 mt-2 gap-1 text-gray-400">
                          <div
                            className="rounded-full text-gray-400 bg-gray-100 p-2 hover:text-black hover:bg-gray-200 cursor-pointer"
                            onClick={() =>
                              handleButtonClick(
                                handleMoveActivity(
                                  activityIndex,
                                  dayIndex,
                                  dayIndex
                                )
                              )
                            }
                          >
                            <FaGripLines className="w-3 h-3 sm:w-4 sm:h-4 md:w-4 md:h-4 lg:w-4 lg:h-4 xl:w-4 xl:h-4" />
                          </div>

                          <div className="relative inline-block">
                            <div
                              className="rounded-full text-gray-400 bg-gray-100 p-2 hover:text-black hover:bg-gray-200 cursor-pointer"
                              onClick={() => {
                                // Pass a function reference to be executed later
                                handleButtonClick(() =>
                                  handleShareButtonClick(
                                    activityIndex,
                                    dayIndex
                                  )
                                );
                              }}
                            >
                              <FaShare className="w-3 h-3 sm:w-4 sm:h-4 md:w-4 md:h-4 lg:w-4 lg:h-4 xl:w-4 xl:h-4" />
                            </div>

                            {sharePopup.activityIndex === activityIndex &&
                              sharePopup.dayIndex === dayIndex && (
                                <div
                                  ref={popupRef}
                                  className="absolute top-0 right-8 w-40 bg-white border border-gray-300 rounded-md z-10"
                                >
                                  <ul
                                    title="Move to day"
                                    name="moveToDay"
                                    id="days"
                                    className="dropdown-menu font-color-tertiary flex flex-col items-center font-medium text-sm justify-center border-2 border-solid border-[#00000029] outline-none rounded-md"
                                  >
                                    {itinerary.map((it, idx) =>
                                      idx !== dayIndex ? (
                                        <li
                                          className="w-full hover:bg-gray-200 text-center py-2"
                                          key={`share-popup-${idx}`}
                                          value={idx}
                                        >
                                          <button
                                            className="dropdown-item block w-full"
                                            onClick={() => {
                                              if (idx != dayIndex) {
                                                // Pass a function reference to be executed later
                                                handleButtonClick(() =>
                                                  handleMoveActivity(
                                                    activityIndex,
                                                    dayIndex,
                                                    idx
                                                  )
                                                );
                                              }
                                            }}
                                          >
                                            {`${getDay(idx)}, ${getMonth(
                                              idx,
                                              startingDate.getMonth()
                                            )}, ${getDate(
                                              idx,
                                              startingDate.getMonth()
                                            )}`}
                                          </button>
                                        </li>
                                      ) : null
                                    )}
                                  </ul>
                                </div>
                              )}
                          </div>

                          <div
                            className="rounded-full text-gray-400 bg-gray-100 p-2 hover:text-black hover:bg-gray-200 cursor-pointer"
                            onClick={() => {
                              // Pass a function reference to be executed later
                              handleButtonClick(() =>
                                handleDeleteButtonClick(activityIndex, dayIndex)
                              );
                            }}
                          >
                            <FaTrash className="w-3 h-3 sm:w-4 sm:h-4 md:w-4 md:h-4 lg:w-4 lg:h-4 xl:w-4 xl:h-4" />
                          </div>
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <div className="text-center p-4">
                    <p className="text-lg font-semibold text-gray-600">
                      No activities available.
                    </p>
                    <p className="text-sm text-gray-500">
                      Go to the{" "}
                      <span
                        className="font-medium text-blue-600 hover:cursor-pointer"
                        // onClick={() => handleExploreSights()}
                      >
                        Explore Sights Tab
                      </span>{" "}
                      tab to add activities.
                    </p>
                  </div>
                )}
              </AccordianItem>
            ))}
          </Accordian>
        </div>
      )}

      {picture && (
        <div className="h-screen w-screen fixed top-0 left-0 flex items-center justify-evenly bg-black/75 z-[51]">
          <img
            src={picture}
            alt="WMW Hotels"
            className="h-auto w-3/4 max-h-[90%]"
          />
          <span
            className="absolute top-5 right-5 text-white text-2xl cursor-pointer"
            onClick={() => setPicture(null)}
          >
            <Close />
          </span>
        </div>
      )}

      {/* Full-screen popup for login */}
      {!isLoggedIn && showSignInPopup && (
        <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-black/75 z-[100]">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h2 className="text-2xl font-semibold mb-4">Sign In Required</h2>
            <p className="text-lg">Please sign in to use this feature.</p>
            <button
              onClick={() => {
                // Redirect to sign-in page or close the popup
                setShowSignInPopup(false);
              }}
              className="mt-4 px-6 py-2 bg-blue-500 text-white rounded-lg"
            >
              Go to Sign In
            </button>
          </div>
        </div>
      )}
    </>
  );
}
