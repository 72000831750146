import React, { useEffect, useState, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate, useParams } from "react-router-dom";
import logo from "../assets/images/logo.png";
import axios from "axios";
import { GrMoney } from "react-icons/gr";
import { MdOutlineEmojiPeople as Solo } from "react-icons/md";
import { FaPeopleGroup as Friends } from "react-icons/fa6";
import { MdOutlineFamilyRestroom as Family } from "react-icons/md";
import { FaPeoplePulling as Couple } from "react-icons/fa6";
import { MdOutlineWorkOff as Vacation } from "react-icons/md";
import { MdOutlineFestival as Festival } from "react-icons/md";
import { MdOutlineWorkOutline as Work } from "react-icons/md";
import { IoCloseSharp as Close } from "react-icons/io5";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import ReactGA from "react-ga4";
import FooterSmall from "../components/FooterSmall";
import { Helmet } from "react-helmet";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
ReactGA.send({
  hitType: "pageview",
  page: "/create-itinerary",
  title: "create-itinerary-page",
});

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

function CreateItinerary() {
  let userId = localStorage.getItem("userId");
  window.scroll({ top: 0, left: 0, behavior: "smooth" });
  mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;
  const { id } = useParams();
  const [showMap, setShowMap] = useState(false);
  const mapContainer = useRef(null);
  const map = useRef(null);

  const navigate = useNavigate();

  const [persons, setPersons] = useState(1);

  const [tripType, settripType] = useState("solo");
  const [activetripType, setActivetripType] = useState({
    solo: true,
    family: false,
    friends: false,
    couple: false,
  });

  const handletripType = (value) => {
    if (value === "solo") {
      setActivetripType({
        solo: true,
        family: false,
        friends: false,
        couple: false,
      });

      settripType(value);
    } else if (value === "family") {
      setActivetripType({
        solo: false,
        family: true,
        friends: false,
        couple: false,
      });

      settripType(value);
    } else if (value === "friends") {
      setActivetripType({
        solo: false,
        family: false,
        friends: true,
        couple: false,
      });

      settripType(value);
    } else if (value === "couple") {
      setActivetripType({
        solo: false,
        family: false,
        friends: false,
        couple: true,
      });

      settripType(value);
    }
  };

  const [budget, setBudget] = useState("medium");
  const [activeBudget, setActiveBudget] = useState({
    low: false,
    medium: true,
    high: false,
  });

  const handleBudget = (value) => {
    if (value === "low") {
      setActiveBudget({
        low: true,
        medium: false,
        high: false,
      });

      setBudget(value);
    } else if (value === "medium") {
      setActiveBudget({
        low: false,
        medium: true,
        high: false,
      });

      setBudget(value);
    } else if (value === "high") {
      setActiveBudget({
        low: false,
        medium: false,
        high: true,
      });

      setBudget(value);
    }
  };

  const [travellingPurpose, setTravellingPurpose] = useState("vacation");
  const [activeTravellingPurpose, setActiveTravellingPurpose] = useState({
    vacation: true,
    professional_work: false,
    event_festival: false,
  });

  const handleTravellingPurpose = (value) => {
    if (value === "vacation") {
      setActiveTravellingPurpose({
        vacation: true,
        professional_work: false,
        event_festival: false,
      });

      setTravellingPurpose(value);
    } else if (value === "professional_work") {
      setActiveTravellingPurpose({
        vacation: false,
        professional_work: true,
        event_festival: false,
      });

      setTravellingPurpose(value);
    } else if (value === "event_festival") {
      setActiveTravellingPurpose({
        vacation: false,
        professional_work: false,
        event_festival: true,
      });

      setTravellingPurpose(value);
    }
  };

  const [activities, setActivities] = useState([]);
  const [activeActivity, setActiveActivity] = useState({
    beaches: false,
    city_sightseeing: true,
    adventure: false,
    nightlife: false,
    shopping: false,
  });

  const handleActivities = (value) => {
    //console.log(value);

    if (activities.includes(value)) {
      setActivities((prevActivities) =>
        prevActivities.filter((item) => item !== value)
      );
      //console.log(activities);
    } else {
      activities.push(value);
      setActivities(activities);
      //console.log(activities);
    }

    setActiveActivity((prevState) => ({
      ...prevState,
      [value]: !prevState[value],
    }));
  };

  let [showDesktopView, setShowDesktopView] = useState(true);

  const isMobile = useMediaQuery({ query: `(max-width: 1024px)` });

  useEffect(() => {
    window.getyourguide && window.getyourguide.Widgets.Loader.load();
    if (isMobile) {
      setShowDesktopView(false);
    }
  }, [isMobile, showDesktopView]);

  const SetPreferences = async () => {
    const preferences = {
      no_of_persons: persons,
      activities: activities,
      budget: budget,
      tripType: tripType,
      travellingPurpose: travellingPurpose,
      userId: userId ? userId : null
    };

    try {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/trip/set-preferences/${id}`,
          preferences
        )
        .then(() => {
          navigate(`/itinerary/${id}`);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchLocationDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/trip/get-location-details/${id}`,
          {
            withCredentials: false,
          }
        );

        if (map.current) return;
        map.current = new mapboxgl.Map({
          container: mapContainer.current,
          style: "mapbox://styles/mapbox/outdoors-v12",
          projection: "globe",
          center: [response.data.message.long, response.data.message.lat],
          zoom: 8,
        });
      } catch (error) {
        console.error(error);
      }
    };

    fetchLocationDetails();
  }, [id]);

  return (
    <>
      <Helmet>
        <title>
          WanderMeWell : Create AI Itinerary
        </title>
        <meta
          name="description"
          content="Revolutionize your travel itineraries with WanderMeWell AI, the ultimate tool for modern travelers. Our advanced AI trip and travel planner capabilities ensure seamless itinerary planning and booking. Experience the ease of crafting AI-optimized itineraries tailored to your preferences. Plan your trip effortlessly with our innovative technology and start exploring smarter today!"
        />
        <meta
          name="keywords"
          content="WanderMeWell, Wander, Well, AI, Trip Planner AI, AI Trip Planner, AI Itineraries, Travel AI, AI Vacation Planner, Travel Itinerary AI, AI for Travel, AI to Plan Trip, Itinerary Planner, Travel Planner, Google Sheets Itinerary Template, AI Trip Planner Free, Travel Itinerary Template Google Sheets, Optimal Route Planning, Personalized Recommendations, Budget-Friendly Dining, Social Media Integration"
        ></meta>
      </Helmet>
      <div className="flex justify-center items-start h-[91vh] mx-auto pb-3 md:pb-2">
        <div className="flex flex-col pt-8 items-center justify-center h-full w-full lg:w-[60%]">
          <div className="hidden-scrollbar h-[100vh] overflow-y-scroll px-2 overflow-x-hidden w-full sm:px-6">
            <div className="flex flex-col gap-y-5 items-start justify-center w-full mb-6">
              <h2 className="text-base text-black md:text-lg font-bold">
                How many people are travelling?
              </h2>
              <span className="gap-x-3 p-3 items-center justify-start px-2 h-full cursor-pointer rounded-md flex border-[1px] border-gray-400 border-solid w-full focus:border-2">
                <select
                  className="no-of-persons font-color-tertiary font-medium text-base  flex items-center justify-center w-full border-none outline-none"
                  name="persons"
                  value={persons}
                  onChange={(ele) => setPersons(ele.currentTarget.value)}
                >
                  <option className="text-base" value="1">
                    1 Person
                  </option>
                  <option className="text-base" value="2">
                    2 Persons
                  </option>
                  <option className="text-base" value="3">
                    3 Persons
                  </option>
                  <option className="text-base" value="4">
                    4 Persons
                  </option>
                  <option className="text-base" value="5">
                    5 Persons
                  </option>
                  <option className="text-base" value="6">
                    6 Persons
                  </option>
                  <option className="text-base" value="7">
                    7 Persons
                  </option>
                  <option className="text-base" value="8">
                    8 Persons
                  </option>
                  <option className="text-base" value="9">
                    9 Persons
                  </option>
                  <option className="text-base" value="10">
                    10 Persons
                  </option>
                </select>
              </span>
            </div>

            <div className="flex flex-col gap-y-5 items-start justify-center w-full mb-6">
              <span className="text-base text-black md:text-lg font-bold">
                Who do you plan on travelling with?
              </span>
              <div className="flex flex-wrap gap-5 items-center justify-start w-full">
                <span
                  className={`${
                    activetripType.solo
                      ? "border-2 border-black bg-blue-200"
                      : "border-[1px] border-gray-400"
                  } flex flex-col items-start justify-center w-36 py-1 px-2 sm:w-40 rounded-md cursor-pointer border-solid hover:border-black`}
                  onClick={() => handletripType("solo")}
                >
                  <Solo className="text-xl" />
                  <h3 className="text-base font-semibold">Solo</h3>
                </span>

                <span
                  className={`${
                    activetripType.family
                      ? "border-2 border-black bg-blue-200"
                      : "border-[1px] border-gray-400"
                  } flex flex-col items-start justify-center w-36 py-1 px-2 sm:w-40 rounded-md cursor-pointer border-solid hover:border-black`}
                  onClick={() => handletripType("family")}
                >
                  <Family className="text-xl" />
                  <h3 className="text-sm font-semibold">Family</h3>
                </span>

                <span
                  className={`${
                    activetripType.friends
                      ? "border-2 border-black bg-blue-200"
                      : "border-[1px] border-gray-400"
                  } flex flex-col items-start justify-center w-36 py-1 px-2 sm:w-40 rounded-md cursor-pointer border-solid hover:border-black`}
                  onClick={() => handletripType("friends")}
                >
                  <Friends className="text-xl" />
                  <h3 className="text-sm font-semibold">Friends</h3>
                </span>

                <span
                  className={`${
                    activetripType.couple
                      ? "border-2 border-black bg-blue-200"
                      : "border-[1px] border-gray-400"
                  } flex flex-col items-start justify-center w-36 py-1 px-2 sm:w-40 rounded-md cursor-pointer border-solid hover:border-black`}
                  onClick={() => handletripType("couple")}
                >
                  <Couple className="text-xl" />
                  <h3 className="text-sm font-semibold">Couple</h3>
                </span>
              </div>
            </div>

            <div className="flex flex-col gap-y-5 items-start justify-center w-full mb-6">
              <span className="text-base text-black md:text-lg font-bold">
                What's the purpose of travelling ?
              </span>
              <div className="flex flex-wrap gap-5 items-center justify-start w-full">
                <span
                  className={`${
                    activeTravellingPurpose.vacation
                      ? "border-2 border-black bg-blue-200"
                      : "border-[1px] border-gray-400"
                  } flex flex-col items-start justify-center w-36 py-1 px-2 sm:w-40 rounded-md cursor-pointer border-solid hover:border-black`}
                  onClick={() => handleTravellingPurpose("vacation")}
                >
                  <Vacation className="text-xl" />
                  <h3 className="text-sm font-semibold">Vacation</h3>
                </span>

                <span
                  className={`${
                    activeTravellingPurpose.event_festival
                      ? "border-2 border-black bg-blue-200"
                      : "border-[1px] border-gray-400"
                  } flex flex-col items-start justify-center w-36 py-1 px-2 sm:w-40 rounded-md cursor-pointer border-solid hover:border-black`}
                  onClick={() => handleTravellingPurpose("event_festival")}
                >
                  <Festival className="text-xl" />
                  <h3 className="text-sm font-semibold">Event / Festival</h3>
                </span>

                <span
                  className={`${
                    activeTravellingPurpose.professional_work
                      ? "border-2 border-black bg-blue-200"
                      : "border-[1px] border-gray-400"
                  } flex flex-col items-start justify-center w-36 py-1 px-2 sm:w-40 rounded-md cursor-pointer border-solid hover:border-black`}
                  onClick={() => handleTravellingPurpose("professional_work")}
                >
                  <Work className="text-xl" />
                  <h3 className="text-sm font-semibold">Professional Work</h3>
                </span>
              </div>
            </div>

            <div className="flex flex-col gap-y-5 items-start justify-center w-full mb-6">
              <span className="text-base text-black md:text-lg font-bold">
                Which activities are you interested in ? You can select multiple
                activities.
              </span>
              <div className="flex flex-wrap gap-5 items-center justify-start w-full">
                <span
                  className={`${
                    activeActivity.beaches
                      ? "border-2 border-black bg-blue-200"
                      : "border-[1px] border-gray-400"
                  } flex flex-col items-start justify-center w-36 py-1 px-2 sm:w-40 rounded-md cursor-pointer border-solid hover:border-black`}
                  onClick={() => handleActivities("beaches")}
                >
                  <Solo className="text-xl" />
                  <h3 className="text-sm font-semibold">Beaches</h3>
                </span>

                <span
                  className={`${
                    activeActivity.adventure
                      ? "border-2 border-black bg-blue-200"
                      : "border-[1px] border-gray-400"
                  } flex flex-col items-start justify-center w-36 py-1 px-2 sm:w-40 rounded-md cursor-pointer border-solid hover:border-black`}
                  onClick={() => handleActivities("adventure")}
                >
                  <Friends className="text-xl" />
                  <h3 className="text-sm font-semibold">Adventure</h3>
                </span>

                <span
                  className={`${
                    activeActivity.nightlife
                      ? "border-2 border-black bg-blue-200"
                      : "border-[1px] border-gray-400"
                  } flex flex-col items-start justify-center w-36 py-1 px-2 sm:w-40 rounded-md cursor-pointer border-solid hover:border-black`}
                  onClick={() => handleActivities("nightlife")}
                >
                  <Couple className="text-xl" />
                  <h3 className="text-sm font-semibold">Nightlife</h3>
                </span>

                <span
                  className={`${
                    activeActivity.shopping
                      ? "border-2 border-black bg-blue-200"
                      : "border-[1px] border-gray-400"
                  } flex flex-col items-start justify-center w-36 py-1 px-2 sm:w-40 rounded-md cursor-pointer border-solid hover:border-black`}
                  onClick={() => handleActivities("shopping")}
                >
                  <Couple className="text-xl" />
                  <h3 className="text-sm font-semibold">Shopping</h3>
                </span>

                <span
                  className={`${
                    activeActivity.city_sightseeing
                      ? "border-2 border-black bg-blue-200"
                      : "border-[1px] border-gray-400"
                  } flex flex-col items-start justify-center w-36 py-1 px-2 sm:w-40 rounded-md cursor-pointer border-solid hover:border-black`}
                  onClick={() => handleActivities("city_sightseeing")}
                >
                  <Family className="text-xl" />
                  <h3 className="text-sm font-semibold">City Sightseeing</h3>
                </span>
              </div>
            </div>

            <div className="flex flex-col gap-y-5 items-start justify-center w-full mb-6">
              <span className="text-base text-black md:text-lg font-bold">
                What's your budget, for travelling ?
              </span>
              <div className="flex flex-wrap gap-5 items-center justify-start w-full mb-4 md:mb-1">
                <span
                  className={`${
                    activeBudget.low
                      ? "border-2 border-black bg-blue-200"
                      : "border-[1px] border-gray-400"
                  } flex flex-col items-start justify-center w-36 py-1 px-2 sm:w-40 rounded-md cursor-pointer border-solid hover:border-black`}
                  onClick={() => handleBudget("low")}
                >
                  <GrMoney className="text-xl" />
                  <h3 className="text-sm font-semibold">Low</h3>
                </span>

                <span
                  className={`${
                    activeBudget.medium
                      ? "border-2 border-black bg-blue-200"
                      : "border-[1px] border-gray-400"
                  } flex flex-col items-start justify-center w-36 py-1 px-2 sm:w-40 rounded-md cursor-pointer border-solid hover:border-black`}
                  onClick={() => handleBudget("medium")}
                >
                  <GrMoney className="text-xl" />
                  <h3 className="text-sm font-semibold">Medium</h3>
                </span>

                <span
                  className={`${
                    activeBudget.high
                      ? "border-2 border-black bg-blue-200"
                      : "border-[1px] border-gray-400"
                  } flex flex-col items-start justify-center w-36 py-1 px-2 sm:w-40 rounded-md cursor-pointer border-solid hover:border-black`}
                  onClick={() => handleBudget("high")}
                >
                  <GrMoney className="text-xl" />
                  <h3 className="text-sm font-semibold">High</h3>
                </span>
              </div>
            </div>
          </div>

          <div className="h-11 flex flex-row-reverse fixed bottom-0 left-0 bg-white z-50 md:relative items-center justify-between w-full px-3 pb-1 border-t-2 border-solid border-gray-400 ">
            <Link
              type="button"
              className="z-1 inline-block text-center select-none border font-semibold whitespace-no-wrap rounded px-2 py-1 no-underline bg-[#fd630d] text-white hover:scale-110 my-3 transition-all"
              onClick={() => SetPreferences()}
            >
              Create
            </Link>

            <button
              className="z-1 inline-block align-middle text-center select-none border whitespace-no-wrap rounded py-1 px-2 leading-normal no-underline bg-[#fd630d] font-semibold text-white lg:hidden"
              type="button"
              onClick={() => setShowMap(!showMap)}
            >
              Show map
            </button>
          </div>
        </div>

        {
          <>
            {showDesktopView && (
              <div className={` map w-full lg:w-[40%]`}>
                <div
                  ref={mapContainer}
                  className="map-container"
                  style={{
                    minHeight: "500px",
                    overflow: "hidden",
                    height: "100vh",
                    width: "100%",
                    zIndex: "1",
                  }}
                />
              </div>
            )}
            {!showDesktopView && (
              <div
                className={`bg-color-secondary z-10 duration-500 ${
                  !showMap ? "translate-x-full" : "translate-x-0"
                } flex flex-col fixed h-full w-full top-0 right-0`}
              >
                <div className="w-full lg:hidden flex justify-between items-center px-1.5">
                  <img src={logo} className="w-10" alt="logo" />
                  <h5 className="text-center text-lg font-semibold align-middle">
                    Map View
                  </h5>
                  <Close
                    className="text-3xl cursor-pointer font-extrabold text-orange-500"
                    onClick={() => setShowMap(!showMap)}
                  />
                </div>
                <div
                  ref={mapContainer}
                  className="map-container"
                  style={{
                    minHeight: "500px",
                    overflow: "hidden",
                    height: "100vh",
                    width: "100%",
                    zIndex: "0",
                  }}
                />
              </div>
            )}
          </>
        }
      </div>
      <div className="hidden lg:block">
        <FooterSmall />
      </div>
    </>
  );
}
export default CreateItinerary;
