import React, { useState, useEffect } from "react";
import TripCard from "../components/TripCard";
import defaultImageURL from "../assets/images/Manali.webp";
import Footer from "../components/Footer";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";


ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
ReactGA.send({
  hitType: "pageview",
  page: "/your-trips",
  title: "your-trips-page",
});

const YourTrips = () => {
  const userName = localStorage.getItem("userName");
  const userId = localStorage.getItem("userId");
  const [trips, setTrips] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTrips = async () => {
      setLoading(true);
      try {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
          userId: userId ? userId : "",
        });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/tripOfUser`,
          requestOptions
        );
        if (!response.ok) {
          console.log(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();

        if (data) {
          setTrips(data);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTrips();
  }, [userId]);

  const handleDelete = async (uid,userId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/tripOfUser/deleteTrip`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ uid,userId }),
        }
      );
      if (!response.ok) {
        console.error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      if (data.message === "Trip deleted successfully") {
        setTrips(trips.filter((trip) => trip.uid !== uid));
      }
    } catch (error) {
      console.error("Error deleting trip:", error);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-96">Loading...</div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-96">
        Error: {error}
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>WanderMeWell : Your AI Trip Itineraries</title>
        <meta
          name="description"
          content="Revolutionize your travel itineraries with WanderMeWell AI, the ultimate tool for modern travelers. Our advanced AI trip and travel planner capabilities ensure seamless itinerary planning and booking. Experience the ease of crafting AI-optimized itineraries tailored to your preferences. Plan your trip effortlessly with our innovative technology and start exploring smarter today!"
        />
        <meta
          name="keywords"
          content="WanderMeWell, Wander, Well, AI, Trip Planner AI, AI Trip Planner, AI Itineraries, Travel AI, AI Vacation Planner, Travel Itinerary AI, AI for Travel, AI to Plan Trip, Itinerary Planner, Travel Planner, Google Sheets Itinerary Template, AI Trip Planner Free, Travel Itinerary Template Google Sheets, Optimal Route Planning, Personalized Recommendations, Budget-Friendly Dining, Social Media Integration"
        ></meta>
      </Helmet>
      <div className="z-50 container mx-auto px-5 py-2 md:px-10 lg:px-20 md:py-5 lg:pt-10 mt-5 min-h-[82vh]">
        {userId && (
          <div className="text-center">
            <div className="flex justify-between">
              <h2 className="text-3xl font-bold mb-4 text-[#FD630D]">
                Profile
              </h2>
              <button
                className="bg-red-500 hover:bg-red-700 text-white font-bold px-2 rounded text-right mr-2"
                onClick={() => {
                  localStorage.removeItem("userId");
                  localStorage.removeItem("userPhoto");
                  localStorage.removeItem("userName");
                  localStorage.removeItem("user");
                  window.location.reload();
                  window.open(
                    `${process.env.REACT_APP_API_URL}/auth/logout`,
                    "_self"
                  );
                }}
              >
                Logout
              </button>
            </div>
            <div className="flex flex-col justify-between items-start">
              <div>
                <p className="text-lg font-bold">
                  User: {userName ? userName : "Unknown"}
                </p>
              </div>
              <div>
                <p className="text-lg font-bold">Credit: Rs.500</p>
              </div>
            </div>
          </div>
        )}

        <div className="mt-8">
          <h2 className="text-2xl font-bold mb-2">Your Trips</h2>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
            {trips &&
              trips.map((trip) => (
                <TripCard
                  key={trip.uid}
                  uid={trip.uid}
                  placeName={trip.placeName}
                  photoUrl={
                    trip.trip_itinerary?.itinerary?.[0]?.activities?.[0]?.photos
                      ?.length > 0
                      ? `https://places.googleapis.com/v1/${trip.trip_itinerary.itinerary[0].activities[0].photos[0].name}/media?maxHeightPx=1024&maxWidthPx=1920&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
                      : defaultImageURL // Use a placeholder image
                  }
                  date={trip.date}
                  days={trip.days}
                  link={`/itinerary/${trip.uid}`}
                  onDelete={()=>handleDelete(trip.uid,userId)}
                />
              ))}
          </div>
          <div>
            {trips && trips.length === 0 && (
              <>
                <h3>
                  You haven't created any trips yet, let's start!{" "}
                  <button
                    className="bg-red-500 hover:bg-red-700 text-white font-bold px-2 rounded text-right"
                    onClick={() => {
                      window.open(`/`, "_self");
                    }}
                  >
                    Create
                  </button>
                </h3>
              </>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default YourTrips;
