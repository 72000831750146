import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import logo from "../assets/images/logo.png";
import "../assets/css/accordion.css";
import axios from "axios";

function DownloadPdf() {
  const { id } = useParams();
  const [itinerary, setItinerary] = useState(null);

  const FetchItinerary = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/trip/get-complete-itinerary/${id}`,
        { withCredentials: false }
      );

      //console.log(response.data.message.itinerary._doc);
      setItinerary(response.data.message.itinerary._doc);
      setTimeout(() => {
        window.print();
      }, 2500);
    } catch (error) {
      console.log(error);
    }
  }, [id]);

  useEffect(() => {
    FetchItinerary();
  }, [FetchItinerary]);

  return (
    <>
      {itinerary ? (
        <div
          className="container mx-auto sm:px-4 bg-white absolute top-0"
          id="pdf"
          data-bs-theme={"light"}
          style={{ minHeight: "100vh", minWidth: "100vw", zIndex: "100" }}
        >
          <div className="top w-full justify-center flex flex-col">
            <img
              src={logo}
              className="h-32 w-32 mx-auto  align-items-center"
              alt="WanderMeWell"
            />
            <span className="text-black text-center pb-2 font-black text-2xl">
              WanderMeWell
            </span>
          </div>
          <hr className="h-[2px] my-5 w-[95vw] mx-auto bg-black" />
          <div className="info w-full text-center my-5">
            <h5 className="text-black text-lg font-semibold">
              Location : {itinerary.placeName}, {itinerary.placeFormatted}
            </h5>
            <h5 className="text-black text-lg font-semibold capitalize">
              No. Of Days : {itinerary.days}
            </h5>
            <h5 className="text-black text-lg font-semibold">
              Latitude : {itinerary.lat} deg{" "}
            </h5>
            <h5 className="text-black text-lg font-semibold">
              Longitude : {itinerary.long} deg
            </h5>
          </div>
          <hr className="h-[2px] my-5 w-[95vw] mx-auto bg-black" />
          <div className="main">
            {itinerary && (
              <div
                alwaysopen = "true"
                flush="true"
                style={{ backgroundColor: "transparent" }}
                defaultactivekey="0"
                data-bs-theme={"light"}
              >
                {itinerary.trip_itinerary.itinerary.map((item, index) => (
                  <div eventkey={index} key={"accordian-item-" + index}>
                    <h5
                      className="p-2 text-left pl-4 text-xl font-bold"
                      key={"accordian-item-header-h5-" + index}
                    >
                      {item.day}
                    </h5>
                    {item.activities &&
                      item.activities.map((activity, index) => (
                        <div key={"item-activity-div-" + index}>
                          <div
                            className="flex flex-col items-start justify-start mx-auto sm:px-4 px-4"
                            style={{
                              borderRadius: "45",
                            }}
                            key={"activity-body-div-" + { index }}
                          >
                            <h3
                              className="text-[#fd630d] font-semibold"
                              key={"activity-body-activity-" + { index }}
                            >
                              {activity.activity}
                            </h3>
                            <p
                              className="font-semibold"
                              key={"activity-body-displayName-" + { index }}
                            >
                              {activity?.displayName?.text}
                            </p>
                            <p
                              className=""
                              key={"activity-body-remarks-" + { index }}
                            >
                              {activity.remarks}
                            </p>
                            <span key={"activity-body-time-" + { index }}>
                              <span className="mr-2 font-semibold">
                                Time :-{" "}
                              </span>{" "}
                              {activity.timings}
                            </span>{" "}
                            <span key={"activity-body-duration-" + { index }}>
                              <span className="mr-2 font-semibold">
                                Duration :-{" "}
                              </span>
                              ({activity.duration}){" "}
                            </span>
                            <span key={"activity-body-cost-" + { index }}>
                              <span className="mr-2 font-semibold">
                                Cost :-{" "}
                              </span>{" "}
                              {activity.entryFees}
                            </span>
                          </div>
                          <br key={"activity-br-" + { index }} />
                        </div>
                      ))}
                    <h5 className="pl-4 text-left p-2 font-semibold">
                      Total cost for {item.day} (local currency) -{" "}
                      {item.totalCostOfDay}
                    </h5>
                    <hr className="h-[2px] my-5 w-[95vw] mx-auto bg-black" />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>Sorry download is unavailable</div>
      )}
    </>
  );
}

export default DownloadPdf;
