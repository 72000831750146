import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import FooterSmall from "../components/FooterSmall";
import logo from "../assets/images/logo.png";
import TabNavigation from "../components/TabNavigation.js";
import ActivityDetails from "../components/ActivityDetails.js";
import HotelDetails from "../components/HotelDetails.js";
import Skeleton from "@mui/material/Skeleton";
import { IoCloseSharp as Close } from "react-icons/io5";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import "../assets/css/Itinerary.css";
import { MdOutlineFileDownload as Download } from "react-icons/md";
import { FaLink as CopyLink } from "react-icons/fa6";
import BookWithUs from "../components/BookWithUs.js";
import ReactGA from "react-ga4";
import axios from "axios";
import { Helmet } from "react-helmet";
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
ReactGA.send({ hitType: "pageview", page: "/itinerary", title: "itinerary-page" });

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

function Itinerary() {
  let userId = localStorage.getItem("userId");
  mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;
  const { id } = useParams();
  const [showMap, setShowMap] = useState(false);

  const mapContainer = useRef(null);
  const map = useRef(null);

  const [priorDetails, setPriorDetails] = useState(null);
  const [about, setAbout] = useState(null);
  const [itinerary, setItinerary] = useState(null);
  const [travelOptions, setTravelOptions] = useState(null);
  const [exploreSights, setExploreSights] = useState(null);
  const [sightsLoading, setSightsLoading] = useState(true);
  const [activity, setActivity] = useState(null);
  const [currentPicture, setCurrentPicture] = useState(null);
  const [currentHotel, setCurrentHotel] = useState(null);
  const [showActivityModal, setShowActivityModal] = useState(false);
  const [showCurrentHotelModal, setShowCurrentHotelModal] = useState(false);

  let [showDesktopView, setShowDesktopView] = useState(true);

  const isMobile = useMediaQuery({ query: `(max-width: 1024px)` });

  useEffect(() => {
    window.getyourguide && window.getyourguide.Widgets.Loader.load();
 
    if (isMobile) {
      setShowDesktopView(false);
    }
  }, [isMobile, showDesktopView]);

  const [googlePlaceIdDetails, setgooglePlaceIdDetails] = useState();

  // function to generate About itinerary
  const GenerateAbout = async (placeName, placeFormatted, googlePlaceId) => {
    try {
      await axios
        .post(`${process.env.REACT_APP_API_URL}/api/about`, {
          placeName: placeName,
          placeFormatted: placeFormatted,
          googlePlaceId: googlePlaceId,
        })
        .then((resp) => {
          //console.log("about -> ", resp.data);
          setAbout(resp.data);
          //console.log(googlePlaceId);
          setgooglePlaceIdDetails(googlePlaceId);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const GenerateIinerary = useCallback(
    async (details) => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/itinerary/${id}`,
          JSON.parse(details)
        );
        setItinerary(response.data);
      } catch (error) {
        console.error(error);
      }
    },
    [id]
  );

  // This code with help to fetch activity data from googlePlaceId
  
  const GenerateItinerary = useCallback(
    async (details) => {
      try {
        // Fetch the itinerary from the API
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/itinerary/${id}`,
          JSON.parse(details)
        );
        
        const itineraryData = response.data;

        // Function to fetch activity details using googlePlaceId
        const fetchActivityDetails = async (googlePlaceId) => {
          const activityResponse = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/activity`,
            {
              googlePlaceId: googlePlaceId,
            }
          );
          //console.log(googlePlaceId);
          //console.log(activityResponse.data);
          return activityResponse.data;
        };
  
        // Loop through each day's activities and fetch their details
        for (const day of itineraryData.itinerary) {
          for (const activity of day.activities) {
            const activityDetails = await fetchActivityDetails(activity.googlePlaceId);
            activity.details = activityDetails;
          }
        }
  
        // Update the state with the detailed itinerary
        setItinerary(itineraryData);
      } catch (error) {
        console.error(error);
      }
    },
    [id]
  );
  
  function onCoordinatesChange(coordinates) {
    new mapboxgl.Marker({ color: "#FF0000" })
      .setLngLat([coordinates.longitude, coordinates.latitude])
      .addTo(map.current);
    map.current.flyTo({
      center: [coordinates.longitude, coordinates.latitude],
      essential: true,
      zoom: 15,
      style: "mapbox://styles/mapbox/streets-v11",
    });
  }

  const fetchTravelOptions = useCallback(
    async (givenPlaceName, givenPlaceFormatted) => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/travelOptions/`,
          { placeName: givenPlaceName, placeFormatted: givenPlaceFormatted }
        );
        setTravelOptions(response.data.travelOptions);
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  const fetchDetailsToGenerateItinerary = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/trip/get-prior-itinerary-details/${id}`,
        { withCredentials: false }
      );

      //console.log(response.data.message);
      setPriorDetails(response.data.message);
      //console.log("Printing user on the page from backend");
      //console.log(response?.data?.message?.userId);
      //console.log(userId);
      //console.log(priorDetails?.userId === userId);

      if (map.current) return;
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/outdoors-v12",
        projection: "globe",
        center: [response.data.message.long, response.data.message.lat],
        zoom: 8,
      });

      GenerateAbout(
        response.data.message.placeName,
        response.data.message.placeFormatted,
        response.data.message.googlePlaceId
      );
      GenerateItinerary(JSON.stringify(response.data.message));
      fetchTravelOptions(
        response.data.message.placeName,
        response.data.message.placeFormatted
      );
    } catch (error) {
      console.error(error);
    }
  }, [id, GenerateItinerary, fetchTravelOptions, userId, priorDetails?.userId]);

  useEffect(() => {
    fetchDetailsToGenerateItinerary();
  }, [fetchDetailsToGenerateItinerary]);

  const handleModals = () => {
    setShowActivityModal(false);
    setShowCurrentHotelModal(false);
    setShowMap(false);
  };

  const copyURL = () => {
    navigator.clipboard.writeText(window.location.href);
    alert("URL copied to clipboard");
  };
// Fetching Explore Activity
useEffect(() => {
  const fetchSights = async () => {
    if (priorDetails && googlePlaceIdDetails) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/nearbySearch`,
          {
            latitude: priorDetails.lat,
            longitude: priorDetails.long,
            pageNo: 1,
            placeName: priorDetails.placeName,
            placeFormatted: priorDetails.placeFormatted,
            googlePlaceId: googlePlaceIdDetails,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setExploreSights(response.data);
        //console.log("Google Place Id Details : ", googlePlaceIdDetails);
        setSightsLoading(false);
      } catch (error) {
        console.log(error.message);
      }
    }
  };
  setSightsLoading(true);
  fetchSights();
}, [priorDetails?.lat, priorDetails?.long, priorDetails?.placeName, priorDetails?.placeFormatted,googlePlaceIdDetails]);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"WanderMeWell Itinerary for " + priorDetails?.placeName}</title>
        <meta
          name="description"
          content={`AI generated Travel Itinerary for ${priorDetails?.placeName}, ${priorDetails?.placeFormatted} to provide a smooth experience in your travel journey.`}
        />
        <meta
          name="keywords"
          content={`WanderMeWell, travel, blog, explore, discover, ${priorDetails?.placeName}, ${priorDetails?.placeFormatted}, itinerary, travel options, travel planning , travel insurance, travel agent, travel agency, software travel tech, tech, content, writter, blogs, articles, information, itinerary`}
        />
      </Helmet>
      <div className="flex justify-center items-start mx-auto h-[91vh] w-full">
        <div className="flex flex-col items-center justify-center w-full lg:w-[60%] h-full">
          {priorDetails ? (
            <div className="h-[100vh] overflow-y-scroll w-full mx-auto hidden-scrollbar pb-3 md:pb-2">
              {priorDetails && about ? (
                <div className="relative flex h-[10rem] items-end justify-start md:h-[15rem] 2xl:h-[20rem]">
                  <img
                    className="text-white absolute flex h-full w-full bg-cover  bg-no-repeat object-cover"
                    loading="lazy"
                    width="1920"
                    height="1080"
                    decoding="async"
                    data-nimg="1"
                    alt="place"
                    // style={{ color: "transparent" }}
                    style={{
                      inset: "0px",
                      objectPosition: "center center",
                      color: "transparent",
                    }}
                    src={
                      about?.photos?.[0]?.name
                        ? `https://places.googleapis.com/v1/${about?.photos?.[0]?.name}/media?maxHeightPx=1080&maxWidthPx=1920&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
                        : about.photoUrl ||
                          "https://lh3.googleusercontent.com/places/ANXAkqEMoaKd3Ik5qF4IS9op1HfckrCrDyK457je7YjSDlwj9Ypo5usk0LX3-ZH2rLreIGd-qzJfi4I7C4IJsw8AsfeGzUrqnbRF3AA=s4800-w720-h405"
                    }
                  ></img>
                  <div className="relative z-1 flex flex-col h-full justify-end text-white">
                    <h1 className="py-1 md:text-4xl pl-4 text-start px-5 w-full text-white line-clamp-2 text-4xl font-bold lg:text-6xl z-[2]">
                      {priorDetails.placeName}
                    </h1>
                    <div className="flex flex-row-reverse justify-end items-center p-2 z-[2]">
                      <h5 className="text-white py-1 text-base font-semibold px-3 w-max bg-white/30 text-start border-1 border-slate-300 rounded-lg">
                        {priorDetails.days}
                      </h5>
                      <h5 className="text-white text-base align-bottom font-semibold pl-2 ml-2 py-1 w-[6.5rem] bg-white/30 mr-2 text-start border-1 border-slate-300 rounded-lg">
                        {priorDetails.date}
                      </h5>
                    </div>
                    <div className="flex flex-col justify-start items-start pl-4 pb-2">
                      <span className="flex text-white items-center z-[2] justify-center gap-x-2">
                        <div
                          className="flex items-center bg-white/30 text-start border-1 border-slate-300 rounded-lg px-2 cursor-pointer"
                          onClick={() => copyURL()}
                        >
                          <CopyLink className="text-sm" />
                          <span className="ml-1 text-sm">Copy</span>
                        </div>
                        <Link
                          to={`/itinerary/${id}/download-pdf`}
                          target="_blank"
                        >
                          <div className="flex items-center bg-white/30 text-start border-1 border-slate-300 rounded-lg px-2 cursor-pointer">
                            <Download className="text-sm" />
                            <span className="ml-1 text-sm">Download</span>
                          </div>
                        </Link>
                      </span>
                    </div>
                  </div>
                  <div className=" md:h-[15rem] 2xl:h-[20rem] safe-inset-0 absolute w-full bg-[linear-gradient(180deg,rgba(0,0,0,.4)_0,rgba(0,0,0,.3)+20%,transparent_50%,rgba(0,0,0,.3)_75%,#000)] transition-colors"></div>
                  {/* absolute right-0 top-0 z-10 h-full w-full  */}
                </div>
              ) : (
                <div className="flex items-center justify-center w-full px-2 py-2 ">
                  <Skeleton
                    className="w-full rounded-md"
                    animation="wave"
                    variant="rectangular"
                    height={250}
                  />
                </div>
              )}

              <hr />

              <TabNavigation
                date={priorDetails?.date}
                days={priorDetails?.days}
                about={about}
                itinerary={itinerary ? itinerary.itinerary : null}
                onCoordinatesChange={onCoordinatesChange}
                setActivity={setActivity}
                setShowActivityModal={setShowActivityModal}
                showMap={showMap}
                setShowMap={setShowMap}
                travelOptions={travelOptions}
                exploreSights={exploreSights}
                sightsLoading={sightsLoading}
                placeName={priorDetails.placeName}
                placeFormatted={priorDetails.placeFormatted}
                setItinerary={setItinerary}
                googlePlaceIdDetails={googlePlaceIdDetails}
                showRegenerateOption={userId === priorDetails?.userId}
                currentHotel={currentHotel}
                setCurrentHotel={setCurrentHotel}
                setShowCurrentHotelModal={setShowCurrentHotelModal}
                setCurrentPicture={setCurrentPicture}
              />
            </div>
          ) : (
            <></>
          )}

          <div className="flex flex-row-reverse h-12 fixed bottom-0 left-0 bg-white z-50 lg:relative items-center justify-between w-full px-3 border-t-2 border-solid border-gray-400 ">
            <BookWithUs
              itinerary={itinerary ? itinerary.itinerary : itinerary}
              defaultItinerary={
                "Itinerary for " +
                //priorDetails.days +
                " day trip to " +
                //priorDetails.placeName +
                ", "
                //priorDetails.placeFormatted
              }
            />
            <button
              className="text-center select-none border whitespace-no-wrap rounded py-1 px-2 leading-normal no-underline bg-[#fd630d] font-semibold text-white lg:hidden"
              type="button"
              onClick={() => setShowMap(!showMap)}
            >
              {showMap ? "Hide Map" : "Show Map"}
            </button>
          </div>
        </div>

        {
          <div className="absolute flex flex-col items-center justify-center w-full lg:w-[40%] overflow-y-hidden lg:relative">
            {showDesktopView && (
              <div className={`map w-full`}>
                <div
                  ref={mapContainer}
                  className="map-container z-0"
                  style={{
                    minHeight: "500px",
                    overflow: "hidden",
                    height: "92vh",
                    width: "100%",
                  }}
                />
              </div>
            )}
            {!showDesktopView && (
              <div
                className={`bg-color-secondary z-10 duration-500 ${
                  !showMap ? "translate-x-full" : "translate-x-0"
                } flex flex-col fixed h-full w-full top-0 right-0 `}
              >
                <div className="w-full lg:hidden flex flex-row justify-between items-center">
                  <img className="w-10" src={logo} alt="logo" />
                  <h5 className="text-center text-lg align-middle font-semibold">
                    Map View
                  </h5>
                  <Close
                    className="text-3xl cursor-pointer font-extrabold text-orange-500"
                    onClick={() => {
                      handleModals();
                    }}
                  />
                </div>
                <div
                  ref={mapContainer}
                  className="map-container"
                  style={{
                    minHeight: "500px",
                    overflow: "hidden",
                    height: "100vh",
                    width: "100%",
                    zIndex: "0",
                  }}
                  
                />
              </div>
            )}
            {currentPicture && <div className="w-36 h-36 bg-white absolute top-5 right-5 z-50 rounded-md drop-shadow-xl">
              <img src={currentPicture} className="h-full w-full object-cover rounded-md drop-shadow-xl p-1" alt="wmw" />
            </div>}
            <ActivityDetails
              showActivityModal={showActivityModal}
              activity={activity}
              setShowActivityModal={setShowActivityModal}
            />
            <HotelDetails
              showCurrentHotelModal={showCurrentHotelModal}
              currentHotel={currentHotel}
              setShowCurrentHotelModal={setShowCurrentHotelModal}
            />
          </div>
        }
      </div>
      <div className="hidden lg:block">
        <FooterSmall />
      </div>
    </>
  );
}
export default Itinerary;
